import { Stack, useTheme } from "@mui/material"
import styles from "../../styles/component.module.scss"

interface BannerProps {
  data: any
}

export default function Banner(props: BannerProps) {
  const { data } = props
  const theme = useTheme()
  return (
    <Stack
      className={styles.peakSeason}
      sx={{
        ".wp-block-button__link": {
          color: `${theme.palette.textPrimary.main}`,
        },
      }}
      mt={5}
      dangerouslySetInnerHTML={{ __html: data }}
    ></Stack>
  )
}
