import { Stack, useTheme } from "@mui/material"
import styles from "../../styles/component.module.scss"

interface PeakSeasonePickProps {
  data: any
}

export default function PeaksSeson(props: PeakSeasonePickProps) {
  const { data } = props
  const theme = useTheme()
  return (
    <Stack
      className={styles.peakSeason}
      sx={{
        ".has-contrast-color": {
          mt: "1.5rem !important",
          mb: "0 !important",
        },
        a: {
          color: `${theme.palette.ctaPrimary.main}`,
          textDecoration: "none",
          fontSize: 18,
        },
      }}
      mt={4}
      dangerouslySetInnerHTML={{ __html: data }}
    ></Stack>
  )
}
