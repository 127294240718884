import { Stack, useTheme } from "@mui/material"
import styles from "../../styles/component.module.scss"

interface Feature2Props {
  data: any
}

export default function Feature2(props: Feature2Props) {
  const { data } = props

  const theme = useTheme()
  return (
    <Stack
      className={styles.peakSeason}
      mt={5}
      sx={{
        ".has-contrast-color": {
          mt: "1.5rem !important",
          mb: "0 !important",
        },
        a: {
          color: `${theme.palette.ctaPrimary.main}`,
          textDecoration: "none",
          fontSize: 18,
          mt: 3,
        },
      }}
      dangerouslySetInnerHTML={{ __html: data }}
    ></Stack>
  )
}
