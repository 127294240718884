// THIS WILL EVENTUALLY REPLACE INDEX, BUT I AM WORKING ON THIS RIGHT NOW
import React, { useContext, useEffect, useState } from "react"
import Categories from "../components/categories/Categories"
import TaxonomyApi from "../services/TaxonomyApi"
import Head from "next/head"
import { Main } from "../components/common/Main"
import generateConfig from "../configs/config"
import { Box, Container } from "@mui/material"
import { useAuth, UserType } from "contexts/AuthContext"
import { useRouter } from "next/router"
import PeaksSeson from "components/PeakSeasonPicks/PeakSeason"
import { useTranslation } from "react-i18next"
import axios from "axios"
import { AppContext } from "contexts/AppContext"
import { openSnackbar } from "contexts/actions/actions"
import Banner from "components/fetaure1Banner/Banner"
import Feature2 from "components/Homefeature2/Feature2"
import styles from "../styles/component.module.scss"
import MadeToOrder from "components/made-to-order/MadeToOrder"
import htmlEncode from "utils/htmlEncode"
import { faroPushErrors } from "utils/faroGrafanaLogs"
const config = generateConfig()
let nodeByUri: any

const cmsQuery = {
  query: `query CmsContent {
    feature1: nodeByUri(uri: "/feature-1") {
       ... on Page {
	    id
	    blocksJSON
	    title
	    content
       }
    }
    feature2: nodeByUri(uri: "/feature-2") {
       ... on Page {
	    id
	    blocksJSON
	    title
	    content
       }
    }
    feature3: nodeByUri(uri: "/feature-3") {
       ... on Page {
	    id
	    blocksJSON
	    title
	    content
       }
    }
    feature4: nodeByUri(uri: "/feature-4") {
       ... on Page {
	    id
	    blocksJSON
	    title
	    content
       }
    }
    madeToOrder: nodeByUri(uri: "/made-to-order-2") {
      ... on Page {
     id
     blocksJSON
     title
     content
      }
   }
   feature5: nodeByUri(uri: "/feature-5") {
      ... on Page {
    id
    blocksJSON
    title
    content
      }
  }
  peakSeasonPicks: nodeByUri(uri: "/peak-season-picks-section") {
      ... on Page {
    id
    blocksJSON
    title
    content
      }
  }
  }
  `,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function TaxonomyPage() {
  const { t } = useTranslation()
  const [categoryData, setCategoryData] = useState(null)
  const [mixedData, setMixedData] = useState(null)
  const [peaskSeasonData, setPeakSeasonData] = useState<any>()
  const [feature1Data, setFeature1Data] = useState<any>()
  const [feature2Data, setFeature2Data] = useState<any>()
  const [feature3Data, setFeature3Data] = useState<any>()
  const [feature4Data, setFeature4Data] = useState<any>()
  const [feature5Data, setFeature5Data] = useState<any>()
  const [featureMadeToOrder, setFeatureMadeToOrder] = useState<any>()
  const overrideHandling = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    displayable: any
  ) => {
    if (displayable?.__override === "cms content") {
      const { data, className } = displayable
      switch (displayable?.__overrideType) {
        case "feature-2":
          return className ? (
            <Box className={className}>
              <Feature2 data={data} />
            </Box>
          ) : (
            <Box>
              <Feature2 data={data} />
            </Box>
          )
        case "banner":
          return <Banner data={data} />
        case "side-by-side":
          return <PeaksSeson data={data} />
        default:
          return null
      }
    }
    return null
  }

  const {
    isAuthenticated,
    authenticate,
    authenticateWithPingCloud,
    updateRegistrationPopUp,
    updateStartSavingLoading,
    swiftlyAccessToken,
  } = useAuth()
  const router = useRouter()

  const { dispatch } = useContext(AppContext)

  useEffect(() => {
    if (localStorage.hasOwnProperty("step")) {
      localStorage.removeItem("step")
      localStorage.removeItem("userInfo")
    }
  }, [])

  React.useEffect(() => {
    // It runs only when defaultUrl changes
    if (router.asPath && router.asPath.indexOf("access_token") > -1) {
      const access_token = router.asPath
        .split("#access_token=")[1]
        .split("&")[0]
      // User is now logged in to our client system, lets log them in to the swiftly system
      // if(localStorage.getItem("access_token")!=null){

      callAuth(access_token)
    } else if (config.pingCloud === "true" && swiftlyAccessToken) {
      authenticateWithPingCloud(swiftlyAccessToken)
    }
  }, [router.asPath])

  const callAuth = async (access_token: string) => {
    const tsmcPingAccessToken: { accessToken: string } = {
      accessToken: access_token ?? "",
      // to test logging in, use token from postman here or set localUser in localstorage.
    }
    authenticate(tsmcPingAccessToken)
  }

  // updateRegistrationPopUp(false);
  // updateStartSavingLoading(false);

  React.useEffect(() => {
    getCmsHomepage()
  }, [])

  const getCmsHomepage = async () => {
    try {
      const result = await axios.post(config?.wordPressAPIUrl, cmsQuery)
      const {
        feature1,
        feature2,
        feature3,
        feature4,
        feature5,
        madeToOrder,
        peakSeasonPicks,
      } = result.data.data
      if (peakSeasonPicks?.content) {
        setPeakSeasonData(peakSeasonPicks?.content)
      }
      if (feature1?.content) {
        setFeature1Data(feature1?.content)
      }
      if (feature2?.content) {
        setFeature2Data(feature2?.content)
      }
      if (feature3?.content) {
        setFeature3Data(feature3?.content)
      }
      if (feature4?.content) {
        setFeature4Data(feature4?.content)
      }
      if (feature5?.content) {
        setFeature5Data(feature5?.content)
      }
      if (madeToOrder?.content) {
        setFeatureMadeToOrder(madeToOrder?.content)
      }
    } catch (error: any) {
      faroPushErrors(JSON.stringify(error?.response?.data))
      dispatch(
        openSnackbar({
          variant: "alert",
          message: error?.response?.data?.message,
          alert: {
            color: "error",
          },
        })
      )
    }
  }

  let pixelDensity = 1
  if (typeof window !== "undefined") {
    pixelDensity = window.devicePixelRatio || 1
  }

  const refetchData = async () => {
    const categoryData = await TaxonomyApi.fetchHome(null)
    setCategoryData(categoryData)
  }

  useEffect(() => {
    async function fetchTaxonomy() {
      let categoryData
      // eslint-disable-next-line prefer-const
      categoryData = await TaxonomyApi.fetchHome(
        null /* TODO(add store number here if showing products)*/
      )
      setCategoryData(categoryData)
    }
    fetchTaxonomy()
  }, [isAuthenticated])

  useEffect(() => {
    const mixedData: any = categoryData
    if (!mixedData) {
      return
    }

    const featureList = {
      foodmaxx: [
        {
          __override: "cms content",
          __overrideType: "banner",
          data: feature1Data,
        },
        {
          __override: "cms content",
          __overrideType: "side-by-side",
          data: peaskSeasonData,
        },
        {
          __override: "cms content",
          __overrideType: "feature-2",
          data: featureMadeToOrder,
        },
        {
          __override: "cms content",
          __overrideType: "feature-2",
          data: feature2Data,
          className: styles.feature5,
        },
      ],
      savemart: [
        {
          __override: "cms content",
          __overrideType: "banner",
          data: feature1Data,
        },
        {
          __override: "cms content",
          __overrideType: "side-by-side",
          data: peaskSeasonData,
        },
        {
          __override: "cms content",
          __overrideType: "feature-2",
          data: featureMadeToOrder,
        },
        {
          __override: "cms content",
          __overrideType: "feature-2",
          data: feature2Data,
          className: styles.feature2,
        },
        {
          __override: "cms content",
          __overrideType: "side-by-side",
          data: feature5Data,
        },
      ],
      luckysupermarkets: [
        {
          __override: "cms content",
          __overrideType: "banner",
          data: feature1Data,
        },
        {
          __override: "cms content",
          __overrideType: "side-by-side",
          data: peaskSeasonData,
        },
        {
          __override: "cms content",
          __overrideType: "feature-2",
          data: featureMadeToOrder,
        },
        {
          __override: "cms content",
          __overrideType: "feature-2",
          data: feature2Data,
          className: styles.feature2,
        },
        {
          __override: "cms content",
          __overrideType: "side-by-side",
          data: feature5Data,
        },
      ],
    }
    if (config.configBanner) {
      for (
        let i = 0;
        i < (featureList as unknown as any)[config.configBanner]?.length;
        i++
      ) {
        mixedData.displayableObjects.splice(
          2 * i + i,
          0,
          (featureList as unknown as any)[config.configBanner][i] as any
        )
      }
    }
    setMixedData(mixedData)
  }, [
    categoryData,
    feature1Data,
    feature2Data,
    peaskSeasonData,
    featureMadeToOrder,
    feature5Data,
  ])

  return (
    <>
      <Head>
        <title>{`${t("metadata.homePageTitle")} | ${config?.siteName}`}</title>
        <meta
          name="description"
          content={htmlEncode(`${t("metadata.homePageDescription")}`)}
        />
        <meta
          name="keywords"
          content={htmlEncode(
            `${t("metadata.homePageKeywords")} | ${config?.siteName}`
          )}
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=2.0; user-scalable=1"
        />
      </Head>
      <h1 style={{ display: "none", textTransform: "capitalize" }}>
        {config.siteTitle}
      </h1>
      <Main>
        {mixedData !== null && (
          <Categories
            merchandisedCategory={mixedData}
            devicePixelRatio={pixelDensity}
            refetchData={refetchData}
            overrideHandling={overrideHandling}
          />
        )}

        <Container maxWidth="xl">
          {!mixedData && <Banner data={feature1Data} />}
          {!mixedData && (
            <Box>
              <PeaksSeson data={peaskSeasonData} />
            </Box>
          )}
        </Container>
      </Main>
    </>
  )
}
